@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);
* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

span,
p,
li,
div,
b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

body > iframe {
  pointer-events: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* @media only screen and (min-device-aspect-ratio: 1/1) and (orientation: landscape) {
  body {
    background-color: black;
    background-image: url("./assets/images/Verticle_Error.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  #root {
    display: none;
  }
} */

/* this css is for warn user on  landscape mode working on IOS / andriod and pwa */
/* @media screen and (min-width: 480px) and (orientation: landscape) {
  body {
    display: flex;
    flex-direction: row;
    background-color: black;
    background-image: url("./assets/images/Verticle_Error.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: auto;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 100vh;
    z-index: 9999999999;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #root {
    display: none;
  }

  div {
    display: none;
  }
}

@media (orientation: portrait) {
  body {
    flex-direction: column;
  }
} */

@media screen and (min-width: 480px) and (orientation: landscape) {
  .autoOreintations {
    display: flex;
    flex-direction: row;
    background-color: black;
    background-image: url(/static/media/Verticle_Error.51601fc0.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: auto;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 100vh;
    z-index: 9999999999;
    align-items: center;
    justify-content: center;
  }

  .inner-panel {
    display: none;
  }
}

@media (orientation: portrait) {
  .autoOreintations {
    flex-direction: row;
  }
}
.overite {
  display: grid;
  height: 100%;
}

